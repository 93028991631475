import consumer from "./consumer"

consumer.subscriptions.create({ channel: "PdfNotificationChannel" }, {
  connected() {
    console.log("Connected to PdfNotificationChannel")
  },
  received(data) {
    console.log("PDF generated:", data.pdf_url)
    showPdfNotification(data.pdf_url)
  }
})

function showPdfNotification(pdfUrl) {
  const notification = document.createElement('div')
  notification.className = 'fixed bottom-5 right-5 bg-green-500 text-white p-4 rounded shadow-lg z-50'
  notification.innerHTML = `
    <p>PDF pronto per il download. <a href="${pdfUrl}" target="_blank" class="underline">Scarica PDF</a></p>
  `
  document.body.appendChild(notification)
  setTimeout(() => notification.remove(), 10000) // Remove notification after 10 seconds
}