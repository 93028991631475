// import Rails from "@rails/ujs";
import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "../stylesheets/application.scss";

require("trix")
require("@rails/actiontext")

Turbo.session.drive = false

// Rails.start();
ActiveStorage.start();

import "../controllers";

import ReactOnRails from "react-on-rails";

import WorkoutSheetEditor from "../bundles/WorkoutSheetEditor";
import WorkoutSheetStatus from "../bundles/WorkoutSheetStatus";
import NutritionalPlanEditor from "../bundles/NutritionalPlanEditor";
import NutritionalPlanStatus from "../bundles/NutritionalPlanStatus";
import NutritionalPlanNotes from "../bundles/NutritionalPlanNotes";
import NutritionalPlanSupplements from "../bundles/NutritionalPlanSupplements";
import ProgressionForm from '../bundles/ProgressionForm';
import Chat from '../bundles/Chat';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  WorkoutSheetEditor,
  WorkoutSheetStatus,
  NutritionalPlanEditor,
  NutritionalPlanStatus,
  NutritionalPlanNotes,
  NutritionalPlanSupplements,
  ProgressionForm,
  Chat
});

